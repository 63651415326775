<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="WHAT'S THE POINT?"
            space="0"
          />

          <base-title
            class="primary--text"
            title="INCENTIVES FOR BOTH HDRN AND HEX HOLDERS"
            tag="div"
          />

          <base-body>
            Icosa exists to both allow HDRN holders to generate yield and to absorb free-market HEX in the form of HSI HEX stakes and distribute those to the community in a way that equally benefits both HDRN and HEX. HDRN stakers can enjoy yield in the form of ICSA as well as the burning effect of the HSI buy-back mechanism. HEX stakers can enjoy decreased sell pressure and the ability to bid on these HSI HEX stakes with freely minted HDRN.
          </base-body>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMarketing',

    data: () => ({
      cards: [
        {
          title: 'Contract Written',
          color: 'primary',
          dark: true,
          icon: 'mdi-check-bold',
        },
        {
          title: 'Interface Created',
          color: 'primary',
          dark: true,
          icon: 'mdi-check-bold',
        },
        {
          title: 'Icosa Deployed',
          color: 'primary',
          dark: true,
          icon: 'mdi-check-bold',
        },
      ],
      marketing: [
        'Decreased HEX Sell Pressure',
        'Increased HEX Staking Incentive',
        'Decreased HDRN Sell Pressure',
        'Increased HDRN Burning Incentive',
        'Scarce Token Supply',
        'Reduced Liquidity Scavenging',
      ],
    }),
  }
</script>
